import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import Tables from "src/components/Table/tableData";
import { Switch, FormControl, Select, MenuItem, Stack } from "@mui/material";
import { NormalText } from "src/components/styled/text.styled";
import {
  getCustomerList,
  CustomerResponse,
  CustomerProps,
  updateCustomerStatus,
} from "src/redux/customer";
import useRedux from "src/redux/useRedux";
import ConfirmationModal from "src/components/Modal/confirmation";
import { toast, ToastContainer } from "react-toastify";
import CheckPermission from "src/helper/checkPermission";

const statusFilter = [
  {
    id: "",
    label: "Semua",
  },
  {
    id: "ACTIVE",
    label: "Aktif",
  },
  {
    id: "INACTIVE",
    label: "Non Aktif",
  },
];

const CustomerListPage = () => {
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<"ACTIVE" | "INACTIVE" | "">("");
  const [customers, setCustomers] = useState<CustomerResponse>();
  const [updateModal, setUpdateModal] = useState(false);
  const [updateId, setUpdateId] = useState(0);
  const [statusUpdate, setStatusUpdate] = useState(false);

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    return new Date(dateString).toLocaleString("id-ID", options).replace(/\//g, "-");
  };

  const columns = [
    {
      name: "Nama",
      cell: (row: CustomerProps) => {
        return row.name;
      },
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      cell: (row: CustomerProps) => {
        return row.email ?? "-";
      },
      sortable: true,
      center: true,
    },
    {
      name: "No Telepon",
      selector: (row: CustomerProps) => row.phone,
      sortable: true,
      center: true,
    },
    {
      name: "Tanggal Daftar",
      selector: (row: CustomerProps) => formatDate(row.register_date),
      sortable: true,
      center: true,
    },
    {
      name: "Pembelian Terakhir",
      cell: (row: CustomerProps) => {
        return formatDate(row.last_purchase);
      },
      sortable: true,
      center: true,
    },
    // {
    //   name: "Status",
    //   center: true,
    //   cell: (row: CustomerProps) => {
    //     return (
    //       <Switch
    //         disabled={!CheckPermission('Customer', 'is_edit')}
    //         checked={row.status}
    //         onChange={(e: any) => {
    //           setStatusUpdate(e.target.checked);
    //           setUpdateId(row.id);
    //           setUpdateModal(true);
    //         }}
    //       />
    //     );
    //   },
    // },
  ];

  const handleUpdateStatus = () => {
    thunkDispatch(updateCustomerStatus({ id: updateId, status: statusUpdate }))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          getAllCustomer();
          toast.success(res.data.message);
          setUpdateModal(false);
        } else if (res && res.status === "error") {
          toast.error(res.error.response.data.message);
        }
      });
  };

  const getAllCustomer = () => {
    setLoading(true);
    thunkDispatch(
      getCustomerList({
        limit: limit,
        offset: offset,
        status: status,
        filter: filter,
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setCustomers(res.data);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    getAllCustomer();
  }, [limit, offset, filter, status]);

  return (
    <MerchantLayout>
      <ToastContainer />
      <ConfirmationModal
        open={updateModal}
        handleClose={() => setUpdateModal(false)}
        handleConfirm={() => handleUpdateStatus()}
        title={"Konfirmasi"}
        description={`Apakah anda yakin ingin ${
          statusUpdate ? "mengaktifkan" : "menon-aktifkan"
        } customer?`}
      />
      <Tables
        records={customers && customers.data ? customers.data : []}
        columns={columns}
        title="Daftar Customer"
        isSearch={true}
        onChangeSearch={(e) => setFilter(e.target.value)}
        valueSearch={filter}
        handleAddButton={() => navigate("/customer/list")}
        progressPending={loading}
        paginationPerPage={limit}
        totalRow={
          customers && customers.meta && customers.meta.total
            ? customers.meta.total
            : 0
        }
        onChangePage={(page) => setOffset(page - 1)}
        onChangeRowsPerPage={(currentRowsPerPage) =>
          setLimit(currentRowsPerPage)
        }
        filter={
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <NormalText textAlign="start" fontSize={"16px"}>
              Filter Status
            </NormalText>
            <FormControl sx={{ minWidth: 250 }}>
              <Select
                value={status}
                onChange={(e) =>
                  setStatus(e.target.value as "ACTIVE" | "INACTIVE" | "")
                }
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ height: 40 }}
              >
                {statusFilter.map((value, index) => {
                  return <MenuItem value={value.id}>{value.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Stack>
        }
      />
    </MerchantLayout>
  );
};

export default CustomerListPage;
