import React, { useState } from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { NormalText } from "src/components/styled/text.styled";
import { Button } from "src/components/styled/button.styled";
import Tables from "src/components/Table/tableData";
import { Box, Stack, Grid, InputBase, Typography } from "@mui/material";
import InputDate from "src/components/Inputs/DatePicker";
import { IconArrowDownCircle, IconArrowUpCircle } from "@tabler/icons";
import Dropdown from "src/components/Inputs/Dropdown";
import OrderGroups from "../layout";
import { formatCurrency, getToken, parseCustomDate } from "src/helper/generalFunction";
import useRedux from "src/redux/useRedux";
import { getList, ClosingResponseProps } from "src/redux/closingCashierReport";
import moment from "moment";
import "moment/locale/id";
import axios, { AxiosRequestConfig } from "axios";
moment.locale("id");
import { styled, alpha } from "@mui/material/styles";
import { toast } from "react-toastify";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "5px",
  border: "1px solid #aaa",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "230px",
  height: "45px",
  marginTop: "10px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "10px",
    maxWidth: "unset",
    width: "230px",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    fontSize: "14px",
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
      width: "100%",
    },
  },
}));

const TransactionReportPage = () => {
  const {
    thunkDispatch,
    storeState: {
      Auth: {
        status,
        data: { user_type, profile_data },
      },
      AppOption: { selected_store },
    },
  } = useRedux();
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [filterStore, setFilterStore] = useState({ id: 0, store_name: "" });
  const [transactions, setTransactions] = useState<ClosingResponseProps>();
  const [filterPayload, setFilterPayload] = React.useState<{
    start_date?: string;
    end_date?: string;
  }>({
    start_date: undefined,
    end_date: undefined,
  });
  const [download, setDownload] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const handleExport = async () => {
    setDownload(true);
    const headers = {
      "Content-Type": "blob",
      api_key: `${process.env.REACT_APP_API_KEY}`,
      token: getToken(),
    };
    let startDate = filterPayload.start_date
      ? moment(filterPayload.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
      : null;
    let endDate = filterPayload.end_date
      ? moment(filterPayload.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
      : null;
    let storeId = String(filterStore?.id ?? "");
    if (
      status &&
      status === "success" &&
      user_type &&
      user_type !== "merchant"
    ) {
      storeId = String(
        selected_store && selected_store.id ? selected_store.id : ""
      );
    }
    let url = `/pos/close-pos/report/download?${
      startDate ? `&start_date=${startDate}` : ""
    }${endDate ? `&end_date=${endDate}` : ""}${
      storeId ? (storeId !== "0" ? `&store_id=${storeId}` : "") : ""
    }${limit ? `&limit=${limit}` : ""}
      ${offset ? `&offset=${offset}` : ""}`;
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}${url}`,
      responseType: "arraybuffer",
      headers,
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        setDownloadProgress(percentage);
      },
    };

    try {
      const response = await axios(config);
      const outputFilename =
        user_type !== "merchant"
          ? `Laporan Transaksi - ${selected_store.name}${
              filterPayload.start_date
                ? " - " +
                  moment(filterPayload.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
                : ""
            }${
              filterPayload?.end_date
                ? " - " + moment(filterPayload.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
                : ""
            }.xlsx`
          : `Laporan Transaksi - ${profile_data.name}${
              filterStore && filterStore.store_name
                ? " - " + filterStore.store_name
                : ""
            }${
              filterPayload.start_date
                ? " - " +
                  moment(filterPayload.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
                : ""
            }${
              filterPayload?.end_date
                ? " - " + moment(filterPayload.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
                : ""
            }${
              !startDate && !endDate
                ? " - " + moment().format('YYYY-MM-DD')
                : ""
            }.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      alert(error);
      setDownload(false);
    }
  };

  const fetchTransactions = () => {
    setLoading(true);
    let storeId = String(filterStore?.id ?? "");
    if (
      status &&
      status === "success" &&
      user_type &&
      user_type !== "merchant"
    ) {
      storeId = String(
        selected_store && selected_store.id ? selected_store.id : ""
      );
    }
    thunkDispatch(
      getList({
        end_date: filterPayload.end_date
          ? moment(filterPayload.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : "",
        limit: limit,
        offset: offset,
        start_date: filterPayload.start_date
          ? moment(filterPayload.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : null,
        store_id: storeId,
        filter: filter,
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          setLoading(false);
          setTransactions(res.data);
        } else {
          setLoading(false);
        }
      });
  };

  React.useEffect(() => {
    let storeId = "";
    if (
      status &&
      status === "success" &&
      user_type &&
      user_type !== "merchant"
    ) {
      storeId = String(
        selected_store && selected_store.id ? selected_store.id : ""
      );
    }
  }, [selected_store]);

  React.useEffect(() => {
    fetchTransactions();
  }, [limit, offset, selected_store, filterStore, filter]);

  React.useEffect(() => {
    if (downloadProgress === 100) {
      setTimeout(() => {
        setDownload(false);
        setDownloadProgress(0);
      }, 500);
    }
  }, [downloadProgress]);

  const columns = [
    {
      name: "Nama Kasir",
      selector: (row: any) => row.cashier_name,
      sortable: true,
    },
    {
      wrap: true,
      name: "Waktu Buka Kasir",
      selector: (row: any) => moment(row.start_time).format('DD-MM-YYYY HH:mm:ss'),
      sortable: true,
    },
    {
      wrap: true,
      name: "Waktu Tutup Kasir",
      selector: (row: any) => moment(row.end_time).format('DD-MM-YYYY HH:mm:ss'),
      sortable: true,
    },
    {
      name: "Kode Sesi",
      cell: (row: any) => row.session_code,
      sortable: true,
    },
    {
      name: "Initial Cash",
      cell: (row: any) => row.initial_cash,
      sortable: true,
    },
    {
      name: "Closing Cash (by System)",
      cell: (row: any) => row.closing_cash_system,
      sortable: true,
    },
    {
      name: "Closing Cash (by Kasir)",
      cell: (row: any) => row.closing_cash_cashier,
      sortable: true,
    },
    {
      name: "Margin Cash",
      cell: (row: any) => row.margin_cash,
      sortable: true,
    },
    {
      name: "Notes",
      cell: (row: any) => row.notes,
    },
  ];

  return (
    <MerchantLayout>
      <OrderGroups tabActive={2}>
        <Box
          sx={{ background: "white", borderRadius: "10px" }}
          padding={"20px"}
        >
          <NormalText fontSize="20px" fontWeight="bold" margin={"0 0 20px 0"}>
            Filter
          </NormalText>
          <Grid container>
            <Grid item md={2} xs={12} sm={12}>
              <NormalText fontSize="15px" fontWeight="bold">
                Pilih Tanggal
              </NormalText>
            </Grid>
            <Grid md={6}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                            <InputDate
                              value={filterPayload.start_date}
                              disableFuture
                              maxDate={parseCustomDate(filterPayload.end_date)}
                              onChange={(v: any) =>
                                setFilterPayload({ ...filterPayload, start_date: v })
                              }
                            />
                            <InputDate
                              disableFuture
                              value={filterPayload.end_date}
                              minDate={parseCustomDate(filterPayload.start_date)}
                              onChange={(v: any) => {
                                if (filterPayload.start_date) {
                                  if (moment(v,"DD-MM-YYYY").isSameOrAfter(moment(filterPayload.start_date,"DD-MM-YYYY"))) {
                                    setFilterPayload({ ...filterPayload, end_date: v });
                                  } else {
                                    toast.warning(
                                      'Tanggal akhir tidak boleh sebelum tanggal awal'
                                    );
                                  }
                                } else {
                                  toast.warning(
                                    'Silahkan pilih tanggal awal terlebih dahulu'
                                  );
                                }
                              }}
                            />
                          </Stack>
            </Grid>
          </Grid>
          <Grid container margin={"20px 0 0 0"}>
            <Grid item xs={12} sm={12} md={2}>
              <NormalText fontSize="15px" fontWeight="bold">
                Cari Nomor Sesi
              </NormalText>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box width={"250px"}>
                <Search>
                  <StyledInputBase
                    placeholder="Search Nomor Sesi"
                    inputProps={{ "aria-label": "search" }}
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </Search>
              </Box>
            </Grid>
          </Grid>
          <Grid container margin={"20px 0 0 0"}>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={10}>
              <Stack direction={"row"}>
                <Button variant="primary" onClick={() => fetchTransactions()}>
                  Filter
                </Button>
                <Button
                  variant="primary"
                  background="#21a421 !important"
                  margin="0 0 0 10px"
                  onClick={() => {
                    handleExport().then((res) => {
                      console.log("BLOG", res);
                    });
                  }}
                  disabled={download === true}
                >
                  Download Excel
                </Button>
                {download ? (
                  <div className="ml-3">
                    <Typography fontSize={"0.8rem"} className="mb-1">
                      Mempersiapkan laporan anda ...
                    </Typography>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Animated striped example"
                    >
                      <div
                        className="progress-bar progress-bar-striped bg-success progress-bar-animated"
                        style={{
                          width: `${downloadProgress ? downloadProgress : 0}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
          <Tables
            progressPending={loading}
            hasContainer={false}
            records={
              transactions && transactions.data && transactions.data.length > 0
                ? transactions.data
                : []
            }
            columns={columns}
            totalRow={transactions ? transactions.meta.total : 0}
            paginationPerPage={limit}
            onChangePage={(page) => setOffset(page - 1)}
            onChangeRowsPerPage={(currentRowsPerPage) =>
              setLimit(currentRowsPerPage)
            }
          />
        </Box>
      </OrderGroups>
    </MerchantLayout>
  );
};

export default TransactionReportPage;
