import * as React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { format, parse } from 'date-fns';

interface DatePickerProps {
  value: any;
  onChange: any;
  minDate?: Date;
  disableFuture?: boolean;
  disabled?: boolean;
  title?: string;
  maxDate?: Date;
}
export default function InputDate({
  value,
  onChange,
  minDate,
  disableFuture,
  disabled,
  title,
  maxDate
}: DatePickerProps) {
  const handleChange = (newValue: Date | null) => {
    const formattedDate = newValue ? format(newValue, 'dd/MM/yyyy') : null;
    onChange(formattedDate);
  };
  const parseDate = (dateString: string) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split('/');
    return parse(`${day}-${month}-${year}`, 'dd-MM-yyyy', new Date());
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div title={title}>
        <DesktopDatePicker
          value={value ? parseDate(value) : null}
          disabled={disabled}
          disableFuture={disableFuture}
          onError={(ev) => console.log("Error callback", ev)}
          minDate={minDate}
          onChange={handleChange}
          inputFormat="dd-MM-yyyy"
          maxDate={maxDate}
          InputProps={{
            sx: { height: 45 },
          }}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField 
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: 'dd-mm-yyyy',
                readOnly: true,
              }}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  );
}
