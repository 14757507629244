import React from 'react';
import MerchantLayout from 'src/components/Layouts/MerchantLayout';
import { useNavigate, useParams } from 'react-router-dom';
import Card from 'src/components/Cards/card';
import { NormalText } from 'src/components/styled/text.styled';
import { Button } from 'src/components/styled/button.styled';
import TextField from 'src/components/Inputs/TextField';
import { Stack, Box, Grid } from '@mui/material';
import {
  ListStoreData,
  showDetailStore,
  ProductStoresPropsViewPage,
} from 'src/redux/storelist';
import useRedux from 'src/redux/useRedux';
import QRCode from 'qrcode.react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

const DetailStorePage = () => {
  // const qrBaseUrl = process.env.NODE_ENV === 'production' 
  // ? (process.env.REACT_APP_QR_BASE_URL_PRODUCTION || 'https://shop.pagii.co')
  // : (process.env.REACT_APP_QR_BASE_URL_STAGING || 'https://pagii-ordering.stg8.smtapps.net');
  const qrBaseUrl = 'https://shop.pagii.co';
  // const sandboxBaseUrl = process.env.NODE_ENV !== 'production' 
  // ? (process.env.REACT_APP_QR_BASE_URL_PRODUCTION || 'https://qr-order.sandbox.co.id')
  // : (process.env.REACT_APP_QR_BASE_URL_STAGING || 'https://sandbox.stagingapps.net');
  const sandboxBaseUrl = 'https://qr-order.sandbox.co.id';
  let QRcode = require('qrcode');
  const navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const { id } = useParams();
  const [detail, setDetail] = React.useState<ListStoreData>();
  const [dataQR, setDataQR] = React.useState<Array<ProductStoresPropsViewPage>>(
    []
  );
  const [server, setServer] = React.useState<any>();

  const qrCodeOpts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    quality: 1,
    width: 220,
    margin: 5,
  };

  React.useEffect(() => {
    thunkDispatch(showDetailStore(id ? parseInt(id) : 0))
      .unwrap()
      .then((res) => {
        if (res && res.status === 'success') setDetail(res.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  React.useEffect(() => {
    setServer(window.location.host);
    
    detail?.product_stores?.forEach((url: any) => {
      url.product_store_qr_code_alias_shop = `${qrBaseUrl}/${detail.store_alias}/home/${url.product_id}`;
      if (detail && detail.store_alias) {
        url.product_store_qr_code_alias = `${qrBaseUrl}/${detail.store_alias}`;
      }
      
      if (url && url.product_store_qr_code_alias) {
        generateQR(url);
      } 
      if (url && url.product_store_qr_code_alias_shop) {
        generateQRShop(url);
      }
    });
  }, [detail]);
  

  const generateQR = (data: any) => {
    if (data) {
      QRcode.toDataURL(
        data?.product_store_qr_code,
        qrCodeOpts,
        function (err: any, url: any) {          
          let dataUrl = {
            product_name: data.product_name,
            product_store_qr_code: url.replace(
              /^data:image\/(png|jpg|jpeg);base64,/,
              ''
            ),
          };
          setDataQR((prevState: any) => [...prevState, dataUrl]);
        }
      );
    } else {
      console.log('data tidak ditemukan');
    }
  };
  
  const generateQRShop = (data: any) => {
    if (data) {
      QRcode.toDataURL(
        data?.product_store_qr_code_alias_shop,
        qrCodeOpts,
        function (err: any, url: any) {          
          let dataUrl = {
            product_name: data.product_name,
            product_store_qr_code_alias_shop: url.replace(
              /^data:image\/(png|jpg|jpeg);base64,/,
              ''
            ),
          };
          setDataQR((prevState: any) => [...prevState, dataUrl]);
        }
      );
    } else {
      console.log('data tidak ditemukan');
    }
  };

  const downloadQR = () => {
    var canvas = document.querySelector(
      '#qrcode > canvas'
    ) as HTMLCanvasElement;
    console.log(canvas);
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `QR-${
      detail && detail.store_name ? detail.store_name : 'Code'
    }.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const downloadQRProducts = () => {
    let zip = new JSZip();
    let img = zip.folder(
      `QR Products - ${detail?.store_name ? detail.store_name : ''}`
    );

    dataQR.forEach((x) => {
      if (x.product_store_qr_code) {
        img?.file(`${x.product_name}.jpg`, x.product_store_qr_code, {
          base64: true,
        });
      } else {
        console.log('data tidak tersedia');
      }
    });

    zip.generateAsync({ type: 'blob' }).then(function (content: any) {
      saveAs(
        content,
        `QR Products - ${detail?.store_name ? detail.store_name : ''}.zip`
      );
    });
  };

  const downloadQRProductsShop = () => {
    let zip = new JSZip();
    let img = zip.folder(
      `QR shop Products - ${detail?.store_name ? detail.store_name : ''}`
    );
  
    dataQR.forEach((x) => {            
      if (x.product_store_qr_code_alias_shop) {
        img?.file(`${x.product_name}.jpg`, x.product_store_qr_code_alias_shop, {
          base64: true,
        });
      } else {
        console.log('data tidak tersedia');
      }
    });
  
    zip.generateAsync({ type: 'blob' }).then(function (content: any) {
      saveAs(
        content,
        `QR shop Products - ${detail?.store_name ? detail.store_name : ''}.zip`
      );
    });
  };

  const copyURL = (url: string) => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard
        .writeText(url ?? 'AVSCCCC')
        .then(() => {
          toast.success(`Berhasil menyalin URL Toko`);
        })
        .catch(() => {
          toast.error(`Gagal menyalin URL Toko!`);
        });
    }
  };

  return (
    <MerchantLayout>
      <Card>
        <Stack
          direction={'row'}
          justifyContent="space-between"
          alignItems="center"
        >
          <NormalText fontWeight="bold" fontSize="20px" margin="0 0 20px 0">
            Detail Toko
          </NormalText>
          <a
            href={
              detail && detail.store_queue_url ? detail.store_queue_url : ''
            }
            target="_blank"
          >
            <Button width="180px" variant={'primary'}>
              Buka Queue
            </Button>
          </a>
        </Stack>
        <TextField
          label={'Nama Toko'}
          isRequired
          value={detail && detail.store_name ? detail.store_name : ''}
          disabled
        ></TextField>
        <TextField
          label={'Alamat'}
          isRequired
          readonly
          value={detail && detail.address ? detail.address : ''}
          disabled
        ></TextField>
        <TextField
          label={'Provinsi'}
          isRequired
          readonly
          value={
            detail && detail.province.province_name
              ? detail.province.province_name
              : ''
          }
          disabled
        ></TextField>
        <TextField
          label={'Kota'}
          isRequired
          readonly
          value={
            detail && detail.city.city_name ? detail.city.city_name : ''
          }
          disabled
        ></TextField>
        <TextField
          label={'Kecamatan'}
          isRequired
          readonly
          value={
            detail && detail.suburb.suburbName
              ? detail.suburb.suburbName
              : ''
          }
          disabled
        ></TextField>
        <TextField
          label={'Kelurahan'}
          isRequired
          readonly
          value={
            detail && detail.area.area_name ? detail.area.area_name : ''
          }
          disabled
        ></TextField>
        <TextField
          label={'Nomor Telepon'}
          isRequired
          readonly
           value={detail && detail.store_phone ? detail.store_phone : ''}
        ></TextField>
       <TextField
        label={'URL Toko'}
        isRequired
        readonly
        type="copyStoreUrl"
        value={
          detail && detail.store_qr_code_alias
          ? detail.store_qr_code_alias.replace(sandboxBaseUrl, qrBaseUrl)
          : ''
        }
        copyUrl={copyURL}
      />
        <TextField
          label={'URL Google Maps'}
          isRequired
          readonly
          value={detail && detail.google_maps_url ? detail.google_maps_url : ''}
        ></TextField>
        <TextField
          label={'Latitude'}
          isRequired
          readonly
          value={detail && detail.latitude ? detail.latitude : ''}
        ></TextField>
        <TextField
          label={'Longitude'}
          isRequired
          readonly
          value={detail && detail.longitude ? detail.longitude : ''}
        ></TextField>
        <TextField
          label={'Nama Alias'}
          readonly
          value={detail && detail.store_alias ? detail.store_alias : ''}
        ></TextField>
<Box
  width={'100%'}
  marginTop={'40px'}
  display={'flex'}
  flexDirection="column"
  alignItems="center"
  justifyContent="center"
  textAlign="center"
>
<a
    href={detail && detail.store_qr_code_alias ? detail.store_qr_code_alias.replace(sandboxBaseUrl, qrBaseUrl) : ''}
    target="_blank"
    rel="noopener noreferrer"
  />
{detail && (
  <Stack direction={'column'} alignItems={'center'}>
    <a
      href={detail.store_qr_code_alias.replace(sandboxBaseUrl, qrBaseUrl)}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none', color: 'black' }}
    >
      <NormalText textAlign="center" fontSize={"12px"} margin="0 0 5px 0">
        {detail.store_qr_code_alias.replace(sandboxBaseUrl, qrBaseUrl)}
      </NormalText>
    </a>
    <div id="qrcode" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <QRCode
        value={`${qrBaseUrl}/${detail.store_alias}`}
        size={220}
        level={'H'}
        renderAs={'canvas'}
        includeMargin={true}
      />
    </div>
  </Stack>
)}

  <Button
          width="220px"
          variant={'primary'}
          onClick={() => downloadQR()}
          style={{ marginTop: '20px' }}
        >
          Download QR Shop
        </Button>
        <Button
          margin="10px 0px 0px 0px"
          width="220px"
          variant={'primary'}
          onClick={() => downloadQRProductsShop()}
          style={{ marginTop: '10px' }}
        >
          Download QR Products Shop
        </Button> 
        <br />
        <a 
    href={
      process.env.NODE_ENV === 'production' 
        ? 'https://pos.sandbox.co.id/login' 
        : 'https://sandbox-pos.stagingapps.net/login'
    } 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <Button width="220px" variant={'primary'}>
      Buka Mini POS
    </Button>
  </a>
</Box>

        <Stack direction={'row'} spacing={2} margin={'20px 0 20px 0'}>
          <Button
            variant="primary"
            onClick={() => navigate('/stores/store/list')}
          >
            Kembali
          </Button>
        </Stack>
      </Card>
    </MerchantLayout>
  );
};

export default DetailStorePage;

