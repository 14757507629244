import React, { useState } from "react";
import MerchantLayout from "src/components/Layouts/MerchantLayout";
import { NormalText } from "src/components/styled/text.styled";
import { Button } from "src/components/styled/button.styled";
import Tables from "src/components/Table/tableData";
import {
  Box,
  Stack,
  Grid,
  Button as ButtonMUI,
  Typography,
} from "@mui/material";
import InputDate from "src/components/Inputs/DatePicker";
import Dropdown from "src/components/Inputs/Dropdown";
import OrderGroups from "../layout";
import useRedux from "src/redux/useRedux";
import { getList, OrderProps, downloadReport } from "src/redux/orderReport";
import moment from "moment";
import FilterStore from "src/views/Order/storeFilter";
import axios, { AxiosRequestConfig } from "axios";
import { formatCurrency, getToken, parseCustomDate } from "src/helper/generalFunction";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const OrderReportPage = () => {
  const formatDateConsistent = (date: string | Date | null) => {
    if (!date) return '';
    return moment(date).format('DD-MM-YYYY');
  };
  const navigate = useNavigate();
  const [download, setDownload] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState<OrderProps[]>();
  const [totalData, setTotalData] = useState(0);
  const [filterStore, setFilterStore] = useState({ id: 0, store_name: "" });
  const [filterPayload, setFilterPayload] = React.useState<{
    start_date: string | null;
    end_date: string | null;
    status: string;
    product_type: string;
  }>({
    start_date: null,
    end_date: null,
    status: "",
    product_type: "",
  });    
  const [downloadProgress, setDownloadProgress] = useState(0);
  const columns = [
    {
      name: "Tanggal",
      wrap: true,
      selector: (row: any) => {
        return (
          <NormalText textAlign="center" fontSize={"12px"}>
            {moment(row.payment_date).format('DD-MM-YYYY HH:mm:ss')}
          </NormalText>
        );
      },
      sortable: true,
    },
    {
      name: "Order No",
      wrap: true,
      selector: (row: any) => {
        return (
          <NormalText textAlign="center" fontSize={"12px"}>
            {row.order_number}
          </NormalText>
        );
      },
      sortable: true,
    },
    {
      name: "Nama Kasir",
      wrap: true,
      selector: (row: any) => {
        return (
          <NormalText textAlign="center" fontSize={"12px"}>
            {row.cashier_name}
          </NormalText>
        );
      },
      sortable: true,
    },
    {
      name: "Nama Customer",
      wrap: true,
      width: "12%",
      selector: (row: any) => {
        return (
          <NormalText textAlign="center" fontSize={"12px"}>
            {row.customer_name}
          </NormalText>
        );
      },
      sortable: true,
    },
    {
      name: "Nama Produk",
      wrap: true,
      selector: (row: any) => {
        return row.order_detail && row.order_detail.length !== 0
          ? row.order_detail.map((item: any, i: number) => (
              <NormalText textAlign="center" fontSize={"12px"}>
                - {item.product_name}
              </NormalText>
            ))
          : null;
      },
      sortable: true,
    },
    {
      name: "Tipe Pembayaran",
      width: "12%",
      selector: (row: any) => {
        return (
          <NormalText textAlign="center" fontSize={"12px"}>
            {row.payment_type.replace("_", " ")}
          </NormalText>
        );
      },
      sortable: true,
    },
    {
      name: "Nominal",
      selector: (row: any) => {
        return (
          <NormalText textAlign="center" fontSize={"12px"}>
            {formatCurrency(Number(row.total_amount_payment))}
          </NormalText>
        );
      },
      sortable: true,
    },
    {
      name: "Status",
      cell: (row: any) => {
        return (
          <NormalText textAlign="center" fontSize={"12px"}>
            {row.status_order.replace("_", " ")}
          </NormalText>
        );
      },
      sortable: false,
    },
    {
      name: "Aksi",
      center: true,
      width: "10%",
      style: { textAlign: "left", paddingLeft: "5px", paddingRight: "5px" },
      cell: (row: any) => {
        return (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <ButtonMUI
              variant="contained"
              color="primary"
              onClick={() => navigate(`/orders/${row.order_number}/detail`)}
              sx={{ width: 80, height: 20, fontSize: 10 }}
            >
              Detail
            </ButtonMUI>
          </Grid>
        );
      },
    },
  ];

  const {
    thunkDispatch,
    storeState: {
      Auth: {
        status,
        data: { user_type, profile_data },
      },
      AppOption: { selected_store },
    },
  } = useRedux();

  const getAll = () => {
    setLoading(true);
    let storeId = String(filterStore.id) ?? "";
    if (
      status &&
      status === "success" &&
      user_type &&
      user_type !== "merchant"
    ) {
      storeId = String(
        selected_store && selected_store.id ? selected_store.id : ""
      );
    }

    let dataObject = {
      offset: offset - 1,
      limit: limit,
      status_order: filterPayload.status,
      start_date: filterPayload.start_date
        ? moment(filterPayload.start_date, 'DD-MM-YYYY').startOf('day').format("YYYY-MM-DD HH:mm:ss")
        : null,
      end_date: filterPayload.end_date
        ? moment(filterPayload.end_date, 'DD-MM-YYYY').endOf('day').format("YYYY-MM-DD HH:mm:ss")
        : null,
      store_id: storeId,
      product_type: filterPayload.product_type,
    };

    thunkDispatch(getList(dataObject))
      .unwrap()
      .then((res) => {
        if (res && res.status === "success") {
          if (res.data.data.length === 0) {
            setData([]);
            setTotalData(0);
            toast.warning("Tidak ada data untuk tanggal yang dipilih.");
          } else {
            setData(res.data.data);
            setTotalData(
              res.data && res.data.meta && res.data.meta.total
                ? res.data.meta.total
                : 0
            );
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleExport = async () => {
    setDownload(true);
    const headers = {
      "Content-Type": "blob",
      api_key: `${process.env.REACT_APP_API_KEY}`,
      token: getToken(),
    };
    let startDate = filterPayload.start_date
      ? moment(filterPayload.start_date).format("YYYY-MM-DD")
      : null;
    let endDate = filterPayload.end_date
      ? moment(filterPayload.end_date).format("YYYY-MM-DD")
      : null;
    let storeId = String(filterStore?.id) ?? "";
    if (user_type && user_type !== "merchant") {
      storeId = String(
        selected_store && selected_store.id ? selected_store.id : ""
      );
    }
    let url = `/merchant/order/reports/data/download?${
      startDate ? `&start_date=${startDate}` : ""
    }${endDate ? `&end_date=${endDate}` : ""}${
      filterPayload.status ? `&status_order=${filterPayload.status}` : ""
    }${storeId ? `&store_id=${storeId}` : ""}`;
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}${url}`,
      responseType: "arraybuffer",
      headers,
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        setDownloadProgress(percentage);
      },
    };

    try {
      const response = await axios(config);

      const outputFilename =
        user_type !== "merchant"
          ? `Laporan Order - ${selected_store.name}${
              filterPayload.start_date
                ? " - " +
                  moment(filterPayload.start_date).format("DD MMMM YYYY")
                : ""
            }${
              filterPayload?.end_date
                ? " - " + moment(filterPayload.end_date).format("DD MMMM YYYY")
                : ""
            }.xlsx`
          : `Laporan Order - ${profile_data.name}${
              filterStore && filterStore.store_name
                ? " - " + filterStore.store_name
                : ""
            }${
              filterPayload.start_date
                ? " - " +
                  moment(filterPayload.start_date).format("DD MMMM YYYY")
                : ""
            }${
              filterPayload?.end_date
                ? " - " + moment(filterPayload.end_date).format("DD MMMM YYYY")
                : ""
            }${
              !startDate && !endDate
                ? " - " + moment().format("DD MMMM YYYY")
                : ""
            }.xlsx`;
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      alert(error);
      setDownload(false);
    }
  };
  React.useEffect(() => {
    getAll();
  }, [selected_store, filterStore, limit, offset]);

  React.useEffect(() => {
    if (downloadProgress === 100) {
      setTimeout(() => {
        setDownload(false);
        setDownloadProgress(0);
      }, 500);
    }
  }, [downloadProgress]);

  return (
    <MerchantLayout>
      <OrderGroups tabActive={0}>
        <Box
          sx={{ background: "white", borderRadius: "10px" }}
          padding={"20px"}
        >
          <NormalText fontSize="20px" fontWeight="bold" margin={"0 0 20px 0"}>
            Filter
          </NormalText>
          <Grid container>
            <Grid item md={2} xs={12} sm={12}>
              <NormalText fontSize="15px" fontWeight="bold">
                Pilih Tanggal
              </NormalText>
            </Grid>
            <Grid md={6}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <InputDate
                  value={filterPayload.start_date}
                  disableFuture
                  maxDate={parseCustomDate(filterPayload.end_date)}
                  onChange={(v: any) =>
                    setFilterPayload({ ...filterPayload, start_date: v })
                  }
                />
                <InputDate
                  disableFuture
                  value={filterPayload.end_date}
                  minDate={parseCustomDate(filterPayload.start_date)}
                  onChange={(v: any) => {
                    if (filterPayload.start_date) {
                      if (moment(v,"DD-MM-YYYY").isSameOrAfter(moment(filterPayload.start_date,"DD-MM-YYYY"))) {
                        setFilterPayload({ ...filterPayload, end_date: v });
                      } else {
                        toast.warning(
                          'Tanggal akhir tidak boleh sebelum tanggal awal'
                        );
                      }
                    } else {
                      toast.warning(
                        'Silahkan pilih tanggal awal terlebih dahulu'
                      );
                    }
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container margin={"20px 0 0 0"}>
            <Grid item xs={12} sm={12} md={2}>
              <NormalText fontSize="15px" fontWeight="bold">
                Pilih Status
              </NormalText>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box width={"250px"}>
                <Dropdown
                  onChange={(e: any) =>
                    setFilterPayload({ ...filterPayload, status: e })
                  }
                  value={filterPayload.status}
                  options={[
                    { label: "Order Baru", value: "NEW_ORDER" },
                    { label: "Proses", value: "PROCESS" },
                    { label: "Siap di Pickup", value: "READY_TO_PICKUP" },
                    { label: "Dikirim", value: "DELIVERY" },
                    { label: "Selesai", value: "CLOSED" },
                    { label: "Batal", value: "CANCELED" },
                  ]}
                ></Dropdown>
              </Box>
            </Grid>
          </Grid>
          {user_type && user_type === "merchant" && (
            <FilterStore
              value={filterStore}
              onChange={(val: any) => {
                setFilterStore(val);
              }}
              filterType={"report"}
            />
          )}
          <Grid container margin={"30px 0 0 0"}>
            <Grid item xs={12} sm={12} md={2}>
              <NormalText fontSize="15px" fontWeight="bold">
                Pilih Tipe Produk
              </NormalText>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box width={"250px"}>
                <Dropdown
                  onChange={(e: any) =>
                    setFilterPayload({ ...filterPayload, product_type: e })
                  }
                  value={filterPayload.product_type}
                  options={[
                    { label: "Semua", value: "all" },
                    { label: "Produk Global", value: "global" },
                    { label: "Produk Toko", value: "store" },
                  ]}
                ></Dropdown>
              </Box>
            </Grid>
          </Grid>
          <Grid container margin={"20px 0 0 0"}>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={10}>
              <Stack direction={"row"}>
                <Button variant="primary" onClick={() => getAll()}>
                  Filter
                </Button>
                <Button
                  variant="primary"
                  background="#21a421 !important"
                  margin="0 0 0 10px"
                  onClick={() => {
                    handleExport().then((res) => {
                      console.log("ok", res);
                    });
                  }}
                  disabled={download === true}
                >
                  Download Excel
                </Button>
                {download ? (
                  <div className="ml-3">
                    <Typography fontSize={"0.8rem"} className="mb-1">
                      Mempersiapkan laporan anda ...
                    </Typography>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Animated striped example"
                    >
                      <div
                        className="progress-bar progress-bar-striped bg-success progress-bar-animated"
                        style={{
                          width: `${downloadProgress ? downloadProgress : 0}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
          <Tables
            hasContainer={false}
            records={data}
            columns={columns}
            valueSearch={search}
            progressPending={loading}
            paginationPerPage={limit}
            totalRow={totalData}
            onChangePage={(page) => setOffset(page)}
            onChangeRowsPerPage={(currentRowsPerPage) =>
              setLimit(currentRowsPerPage)
            }
          />
        </Box>
      </OrderGroups>
    </MerchantLayout>
  );
};

export default OrderReportPage;
