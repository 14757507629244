import React, { useRef } from "react";
import { DateRangePicker } from "react-date-range";
import { id } from "date-fns/locale";
import { Stack, Box } from "@mui/material";
import Popper from "@mui/material/Popper";
import styled from "styled-components";
import { IconCalendar } from "@tabler/icons";
import { NormalText } from "src/components/styled/text.styled";
import "react-date-range/dist/styles.css";
import "./datepicker.css";

export interface RangeProps {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  key: "selection";
}

interface DateRangeProps {
  onChange: any;
  ranges: RangeProps[];
}

const DateRange = ({ ranges, onChange }: DateRangeProps) => {
  const now = useRef(new Date());
  return (
    <SimplePopper
      startDate={ranges[0].startDate ?? ""}
      endDate={ranges[0].endDate}
    >
      <DateRangePicker
        locale={id}
        dateDisplayFormat={"yyyy.MM.dd"}
        maxDate={now.current}
        onChange={onChange}
        editableDateInputs={false}
        ranges={ranges}
      />
    </SimplePopper>
  );
};
const formatDate = (date: Date | undefined) => {
  if (!date) return "";
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

interface SimplePopperProps {
  children: React.ReactNode;
  startDate: any;
  endDate: any;
}

export const SimplePopper = ({
  children,
  startDate,
  endDate,
}: SimplePopperProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <div onMouseLeave={() => setAnchorEl(null)}>
      <ButtonDate aria-describedby={id} type="button" onClick={handleClick}>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <IconCalendar stroke={1} size={"20px"} />
          <NormalText>
            {`${formatDate(new Date(startDate))} - ${formatDate(new Date(endDate))}`}
          </NormalText>
        </Stack>
      </ButtonDate>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <Box sx={{ p: 1, bgcolor: "background.paper" }}>{children}</Box>
      </Popper>
    </div>
  );
};

const ButtonDate = styled.button`
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 12px;
`;

export default DateRange;